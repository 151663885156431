import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import gsap from 'gsap'
import gsapCore from 'gsap/gsap-core'
import { Tween } from 'gsap/gsap-core'
import ScrollTrigger from 'gsap/ScrollTrigger'
import '@google/model-viewer';

gsap.registerPlugin(ScrollTrigger);

const sections = gsap.utils.toArray(".section");
const container = document.querySelector('.container');


gsap.set(container, {
 backgroundColor: 'orange',
})


const tl = gsap.timeline({
  
    scrollTrigger: {
    trigger: "SO",
    scrub: true,   
  }
  
})

 tl
 .to(container, { duration: 1, backgroundColor: '#175b81', ease: "none" }, 0)
 .to(container, { duration: 1, backgroundColor: '#a55062', ease: "none" }, 1)